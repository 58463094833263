//app config

const APP_MODE_TYPES = ["localhost", "dev", "staging", "live"]
const APP_MODE = "localhost";
// const APP_MODE = "dev";
// const APP_MODE = "staging";
// const APP_MODE = "live";

if (process.env.NODE_ENV !== "production") {
    switch (APP_MODE) {
        case 'localhost':
        case 'dev':
            window.bookmy = {
                server_url: "http://slb.bookmy.project8.inscribecms.com/"
                // server_url: "http://bookmy.project8.inscribecms.com/"
            }
            break;
        case 'staging':
            window.bookmy = {
                server_url: "https://app.demo.smartlockbooking.com.au/"
                // server_url: "https://demo.bookmy.com.au/"
            }
            break;
        case 'live':
            window.bookmy = {
                server_url: 'https://app.smartlockbooking.com.au/'
                // server_url: "https://app.bookmy.com.au/"
            }
            break;
    }
}
// define a new console
const newConsole = (function (console) {
    return {
        log: function (text) {
            // console.log(text);
        },
        info: function (text) {
            // console.log(text);
        },
        warn: function (text) {
            // console.log(text);
        },
        error: function (text) {
            // console.log(text);

        }
    };
}(window.console));

if (process.env.NODE_ENV === "production") {
    window.console = newConsole;
}
