import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { LocalStorage } from 'utils/localStorage';
import * as AccAPI from 'services/accounts';
import * as RoleAPI from 'services/role';
// import * as AdsAPI from 'services/advertisements';
import useUserStore from './UserStore';

const STORE_KEY = 'slb-accounts-store';

interface Account {
    id?: string | number;
    [key: string | number]: any;
}
interface Role {
    id?: string | number;
    [key: string | number]: any;
}
type Store = {
    accounts: Account[];
    roleOptions: Role[];
    loadAccounts: ({ businessId }: { businessId: number | string }) => void;
    loadRoleOptions: ({ businessId }: { businessId: number | string }) => void;
    setState: (key: string, value: any) => void;
    updateRoleOption: (id: string | number, value: any) => void;
    updateAccount: (id: string | number, value: any) => void;
    addAccount: (value: Account) => void;
    addRoleOption: (value: Role) => void;
    getAccount: (id: string | number) => Account;
    deleteAccount: (id: string | number) => void;
    deleteRoleOption: (id: string | number) => void;
    resetState: () => void;
};
let initialState = {
    accounts: [],
    roleOptions: []
};
// @ts-ignore
// initialState = LocalStorage.initStore(STORE_KEY, initialState)

const useAccountsStore = create<Store>()(
    // devtools(
    //     persist(
    (set, get) => ({
        ...initialState,
        loadAccounts: async ({ businessId }) => {
            const { data } = await AccAPI.getBusinessAccounts({ business_id: businessId })
            get().setState('accounts', data.data);
        },
        loadRoleOptions: async ({ businessId }) => {
            const { data } = await RoleAPI.getRoles({ businessId: businessId });
            get().setState('roleOptions', data.data);
        },
        setState: (key, value) => {
            set((s) => ({ [key]: value }));
        },
        addAccount: (value) => {
            set((s) => {
                const accounts = s.accounts;
                accounts.push(value);

                return { accounts };
            });
        },
        addRoleOption: (value) => {
            set((s) => {
                const roleOptions = s.roleOptions;
                roleOptions.push(value);

                return { roleOptions };
            });
        },
        updateRoleOption: (id, value) => {
            set((s) => {
                const roleOptions = s.roleOptions.map((x, i) => {
                    if (+x.id! == +id) {
                        return {
                            ...x,
                            ...value
                        };
                    }
                    return x;
                });
                return { roleOptions: roleOptions };
            });
        },
        deleteRoleOption: (id) => {
            const roleOptions = get().roleOptions.filter((x) => x.id != id);
            get().setState('roleOptions', roleOptions);
        },
        updateAccount: (id, value) => {
            set((s) => {
                const newAccounts = s.accounts.map((x, i) => {
                    if (+x.id! == +id) {
                        return {
                            ...x,
                            ...value
                        };
                    }
                    return x;
                });
                return { accounts: newAccounts };
            });
        },
        deleteAccount: (id) => {
            const accounts = get().accounts.filter((x) => x.id != id);
            get().setState('accounts', accounts);
        },
        getAccount: (id) => {
            return get().accounts.find((x) => x.id == id) || {};
        },
        resetState: () => {
            set((s) => initialState);
        },
    })
    //         {
    //             name: STORE_KEY,
    //             storage: createJSONStorage(() => localStorage),
    //             version: 1,
    //         }
    //     )
    // )
);
export default useAccountsStore;
